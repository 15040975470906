import styled from 'styled-components';

export const Container = styled.div`
  .ant-picker-cell-inner {
    background-color: transparent !important;
    /* color: #000 !important; */
    cursor: pointer !important;
  }

  .calendar__day-event {
    border-radius: 50%;
    border: 1px solid red;
    bottom: -3px;
    height: 30px;
    left: 5px;
    margin-bottom: 0;
    position: absolute;
    width: 30px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    display: none;
  }

  .ant-picker-calendar-date-value {
    cursor: pointer;
  }

  .ant-picker-cell-selected {
    .ant-picker-calendar-date-value {
      color: #000;
    }
  }
`;
