import styled from 'styled-components';

export const ExpertsCarouselContainer = styled.div`
  padding: 0;
  position: relative;

  &.single {
    .expert-card {
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        min-width: calc(100vw - 40px);
      }
    }
  }

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 8px;
    margin: 0 20px;
    width: calc(100% - 40px);

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      left: 0;
      margin: 24px 0 0;
      position: relative;
      width: 100%;
    }
  }

  .fade-effect {
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      background: linear-gradient(to left, white, transparent);
      display: block;
      height: calc(100% - 40px);
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
      z-index: 999;
    }
  }

  .swiper-scrollbar-drag {
    background: ${({ theme }) => theme.colors.v2.primary[900]};
  }

  .swiper-slide {
    width: fit-content !important;

    &:last-child {
      margin-right: 0 !important;
      width: 50px !important;
    }
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  padding: 0 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`;
