export const CURRENCIES = [
  {
    name: 'US Dollar',
    symbol: '$',
    code: 'USD',
    countryCode: 'en-US',
  },
  {
    name: 'Brazilian Real',
    symbol: 'R$',
    code: 'BRL',
    countryCode: 'pt-BR',
  },
];
