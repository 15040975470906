import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';

import imgOneDesktop from '../../../../../assets/images/home/carousel-header/image1-desktop.png';
import imgOneMobile from '../../../../../assets/images/home/carousel-header/image1-mobile.png';
import imgTwoDesktop from '../../../../../assets/images/home/carousel-header/image2-desktop.png';
import imgTwoMobile from '../../../../../assets/images/home/carousel-header/image2-mobile.png';
import imgThreeDesktop from '../../../../../assets/images/home/carousel-header/image3-desktop.png';
import imgThreeMobile from '../../../../../assets/images/home/carousel-header/image3-mobile.png';
import imgFourDesktop from '../../../../../assets/images/home/carousel-header/image4-desktop.png';
import imgFourMobile from '../../../../../assets/images/home/carousel-header/image4-mobile.png';
import imgFiveDesktop from '../../../../../assets/images/home/carousel-header/image5-desktop.png';
import imgFiveMobile from '../../../../../assets/images/home/carousel-header/image5-mobile.png';

import * as S from './styles';

const isMobile = window.innerWidth < 1024;

const slides = [
  {
    image: isMobile ? imgOneMobile : imgOneDesktop,
    text: 'home.carousel.one.subtitle',
    title: 'home.carousel.one.title',
  },
  {
    image: isMobile ? imgTwoMobile : imgTwoDesktop,
    text: 'home.carousel.two.subtitle',
    title: 'home.carousel.two.title',
  },
  {
    image: isMobile ? imgThreeMobile : imgThreeDesktop,
    text: 'home.carousel.three.subtitle',
    title: 'home.carousel.three.title',
  },
  {
    image: isMobile ? imgFourMobile : imgFourDesktop,
    text: 'home.carousel.four.subtitle',
    title: 'home.carousel.four.title',
  },
  {
    image: isMobile ? imgFiveMobile : imgFiveDesktop,
    text: 'home.carousel.five.subtitle',
    title: 'home.carousel.five.title',
  },
];

const Carousel = () => {
  const { t } = useTranslation();

  return (
    <S.BannerContainer>
      <Swiper
        modules={[EffectFade, Autoplay]}
        effect="fade"
        autoplay={{ delay: 3000 }}
        loop
        speed={1000}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <S.SlideContent>
              <img
                className="swiper__image"
                src={slide.image}
                alt="Banner"
                style={{
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover',
                }}
              />
              <S.Overlay>
                <S.TextTitle>{t(slide.title)}</S.TextTitle>
                <S.SwiperText>{t(slide.text)}</S.SwiperText>
              </S.Overlay>
            </S.SlideContent>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.BannerContainer>
  );
};

export default Carousel;
