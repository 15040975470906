import React, { createContext, useMemo, useState } from 'react';
import useSWR from 'swr';

import fetcher from 'services/fetcher';

import { MEETING_TYPES } from 'constants/types';

const FindExpertContext = createContext({});

export function FindExpertProvider({ children }) {
  const { data, isLoading, error } = useSWR('topic', fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 0,
    revalidateOnReconnect: false,
  });

  const [filters, setFilters] = useState({
    travel_destination: '',
    scheduling_type: MEETING_TYPES.online,
    page: 0,
    limit: 8,
    size: 8,
  });

  const onChangeFilter = (filters) => {
    setFilters((prev) => ({
      ...prev,
      ...filters,
      page: 0,
    }));
  };

  const getMoreData = () => {
    setFilters((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const value = useMemo(
    () => ({
      filters,
      isSearchPage: window.location.pathname === '/search-travel-expert',
      searchPage: 'search-travel-expert',
      topics: data,
      topicsError: error,
      topicsLoading: isLoading,
      onChangeFilter,
      getMoreData,
    }),
    [data, isLoading, error, filters]
  );

  return (
    <FindExpertContext.Provider value={value}>
      {children}
    </FindExpertContext.Provider>
  );
}

export const useFindExpert = () => React.useContext(FindExpertContext);
