import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const ScheduleDateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 24px 0;

  .schedule-modal__title {
    color: ${({ theme }) => theme.colors.v2.secondary[400]};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.25px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  .schedule-modal__content {
    width: 100%;

    .ant-tabs-nav-list {
      flex-flow: row nowrap;
    }
  }

  .schedule-modal__tab {
    align-items: center;
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    display: flex;
    flex-flow: row nowrap;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 22px;
    text-align: center;
  }

  .search-header__schedule {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 24px;

    .schedule__btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.grayTitle};
      position: relative;
      top: 5px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .schedule__times {
      gap: 48px;
      grid-template-columns: 1fr 1fr 1fr;
      position: relative;
      display: grid;
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: minmax(45px, 100%) minmax(45px, 100%) minmax(
            45px,
            100%
          );
      }
    }

    .schedule__date-container {
      display: flex;
      flex-flow: column wrap;
      text-align: center;
      font-family: ${({ theme }) => theme.fonts.texts};

      span {
        font-weight: 700;
        font-size: ${convertPxToRem(12)};
      }

      span:last-child {
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-size: ${convertPxToRem(16)};
      }
    }
  }

  .found-mentor__schedules {
    margin-bottom: 36px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 20px;
    }

    > div {
      grid-template-columns: repeat(3, minmax(90px, 100%));
    }
  }

  .found-mentor__footer {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 20px;
    }
  }

  .ant-btn {
    margin-bottom: 16px;
    width: 100%;
  }
  .ant-tabs-ink-bar {
    background-color: 1px solid ${({ theme }) => theme.colors.blue[20]};
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-top > .ant-tabs-nav:before {
    border-bottom: 0px solid ${({ theme }) => theme.colors.blue[20]};
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralWhite[40]};
  }
`;
