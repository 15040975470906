import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';
import { Card } from 'antd';

export const MentorCardContainer = styled(Card)`
  box-shadow: 0px 0px 4px 0px #00000040;
  cursor: default;
  margin-bottom: 16px;
  transition: border 0.5s;
  width: 232px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.v2.secondary[400]};
  }

  .ant-card-body {
    padding: 0;
  }

  .mentor-card__cover-photo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 118px;
    width: 100%;
  }

  .mentor-card__header {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    padding: 16px;

    img {
      border-radius: 50%;
      bottom: 6px;
      height: 75px;
      margin-bottom: 2px;
      position: relative;
      width: 75px;
    }

    .mentor-card__country {
      color: ${({ theme }) => theme.colors.v2.neutral[900]};
      display: block;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(16)};
      line-height: 20px;
      margin: 0;
    }

    .mentor-card__name {
      color: ${({ theme }) => theme.colors.v2.neutral[500]};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-weight: 400;
      font-size: ${convertPxToRem(14)};
      text-transform: capitalize;
      margin: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mentor-card__body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 16px;

    .mentor-card__idioms-text {
      color: ${({ theme }) => theme.colors.v2.neutral[500]};
      display: block;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
      font-weight: 600;
      line-height: 15.62px;
      margin-bottom: 6px;
    }

    .mentor-card__idioms-options {
      display: flex;
      flex-flow: row nowrap;
      gap: 4px;
    }

    .mentor-card__idioms-options img {
      height: 15px;
      object-fit: fill;
      width: auto;
    }
  }

  .mentor-card__button {
    width: 100%;
    padding: 0 10px;
    margin-top: 16px;

    button {
      margin: 0;
      width: 100%;
    }
  }

  .mentor-card__specialties {
    margin-top: 8px;
    padding: 0px 16px 24px;

    .mentor-card__specialties-text {
      color: ${({ theme }) => theme.colors.v2.neutral[500]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(14)};
      font-weight: 600;
      display: block;
    }
  }

  .mentor-card__specialties-link {
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(14)};
    font-weight: 800;
    margin-top: 8px;
    text-align: center;
    width: 100%;

    span {
      color: ${({ theme }) => theme.colors.v2.secondary[400]};
      text-decoration: underline;
    }

    &:hover span {
      color: ${({ theme }) => theme.colors.v2.secondary[400]};
      text-decoration: none;
    }
  }
`;
