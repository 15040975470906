import { StarOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Image, Rate, Skeleton, Tooltip } from 'antd';

import useCurrencyParse from 'hooks/useCurrencyParse';

import findCountryNameById from 'utils/findCountryNameById';

import { FlagsCodeImage } from 'constants/flag-code';

import * as S from './styles';

function AdvisorCard({ advisor, isLoadingAdvisor = false }) {
  const { parseCurrencyValue } = useCurrencyParse();
  const navigate = useNavigate();
  const { name, image, imageUrl, city } = advisor;
  const { t } = useTranslation();

  const country = findCountryNameById({
    acronym: advisor?.addressCountry?.acronym,
  });

  const travelExpertImg = imageUrl || image;

  const redirectToPricingTab = () => {
    navigate({ search: 'tab=scheduleData ' });
  };

  if (isLoadingAdvisor) {
    return <Skeleton avatar paragraph={{ rows: 2 }} />;
  }

  return (
    <S.CardContainer className="advisor-card">
      <Image
        src={travelExpertImg}
        alt="Advisor"
        className="advisor-card__avatar"
      />

      <div className="advisor-card__content">
        <h3 className="advisor-card__name">{name}</h3>
        <h4 className="advisor-card__country">
          {country} - {city}
        </h4>

        <div className="advisor-card__info">
          <div className="advisor-card__idioms">
            <span className="advisor-card__idioms-text">
              {t('common.idioms')}:
            </span>
            <span className="advisor-card__idioms-options">
              {advisor?.languages?.map(({ code }) => (
                <Tooltip title={t(`languages.code.${code}`)} key={uuidv4()}>
                  {FlagsCodeImage[code]}
                </Tooltip>
              ))}
            </span>
          </div>
          <div className="advisor-card__idioms">
            <span className="advisor-card__idioms-text">
              {t('common.avaliation')}:
            </span>
            <Rate
              count={5}
              value={Math.round(advisor.rating ?? 0) || 0}
              character={<StarOutlined />}
              disabled
              style={{
                fontSize: 18,
                color: '#D82EA1',
                backgroundColor: 'transparent',
              }}
            />
          </div>
        </div>

        <div className="advisor-card__price">
          <span
            className="advisor-card__price-value pointer"
            onClick={() => redirectToPricingTab()}
          >
            {parseCurrencyValue(advisor.valueHourVirtual)}
          </span>
          <span className="advisor-card__price-text">
            {t('mentorProfile.priceHour')}:
          </span>
        </div>
      </div>
    </S.CardContainer>
  );
}

export default AdvisorCard;
