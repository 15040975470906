import styled from 'styled-components';

import convertPxToRem from 'utils/convert-px-rem';

export const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.v2.primary[900]};
  padding: 20px;

  .footer__container {
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: ${({ theme }) => theme.containerSize};
      margin: 0 auto;
    }
  }

  .footer__contact {
    display: flex;
    flex-flow: column wrap;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .footer__form {
      margin-top: 16px;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        width: 280px;
      }
    }
    .footer__form form {
      display: flex;
      flex-flow: column wrap;
      gap: 10px;
    }

    .newsletter__button {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        align-self: flex-end;
        width: 120px;
      }
    }
  }

  address {
    margin-top: 20px;
    margin-bottom: 8px;
  }

  address span {
    color: ${({ theme }) => theme.colors.v2.backgroundColor};
    font-size: ${convertPxToRem(14)};
    font-style: normal;
  }

  a {
    color: ${({ theme }) => theme.colors.v2.backgroundColor};
    display: block;
    margin-bottom: 8px;
  }

  .footer__info {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 34px;
    }
  }

  .footer__links {
    margin-top: 30px;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-top: 0;
      width: 442px;

      &.footer__links--brazil {
        width: 550px;
      }
    }

    > a {
      display: inline-block;
      text-decoration: underline;
    }
  }

  .footer__links-divider {
    margin: 0 6px;
    color: ${({ theme }) => theme.colors.v2.backgroundColor};
  }

  .footer__divider {
    height: 0.5px;
    background-color: ${({ theme }) => theme.colors.v2.backgroundColor};
    margin: 20px 0;
    width: 100%;
  }

  .footer__social-icons {
    display: flex;
    gap: 20px;
  }

  .footer__affiliates {
    align-items: center;
    display: flex;
    gap: 20px;
    margin-top: 40px;
  }
`;
