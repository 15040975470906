import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import { ContentaInput } from 'components/Styled/ContentaInput';

import * as S from './styles';

function Newsletter() {
  const { t } = useTranslation();

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsSubscribed(true);
    }, 2000);
  };

  return (
    <S.NewsletterContainer>
      <h3>{t('footer.newsletter')}</h3>

      <form
        action="https://contenta360.us14.list-manage.com/subscribe/post?u=b048b708c7bfa536d0867d9fc&id=5e6f6bbbea"
        method="POST"
        target="_blank"
        className="d-flex g-8"
      >
        <ContentaInput
          type="text"
          name="FNAME"
          placeholder="Nome"
          required
          className="border p-2 rounded w-full"
        />
        <ContentaInput
          type="email"
          name="EMAIL"
          placeholder="Seu e-mail"
          required
          className="border p-2 rounded w-full mt-2"
        />
        <button
          type="submit"
          className="ant-btn newsletter__button"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <LoadingOutlined /> : null}

          {t('footer.subscribe')}
        </button>
      </form>

      {isSubscribed && (
        <Typography.Paragraph type="success">
          {t('footer.subscribeSuccess')}
        </Typography.Paragraph>
      )}
    </S.NewsletterContainer>
  );
}

export default Newsletter;
