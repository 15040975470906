import Carousel from './carousel';
import SearchBox from './search-box';

const SearchBar = () => {
  return (
    <>
      <Carousel />
      <SearchBox />
    </>
  );
};

export default SearchBar;
