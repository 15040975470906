import { WhiteButton } from 'components/Styled/v2/WhiteButton';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

const TravelExpertCTA = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <div className="travel-expert-cta">
        <h2
          className="travel-expert-cta__text"
          dangerouslySetInnerHTML={{ __html: t('home.banner.title') }}
        />
        <WhiteButton
          className="travel-expert-cta__button"
          type="link"
          href="https://travelexpert.contenta360.com/"
          target="_blank"
        >
          Learn more
        </WhiteButton>
      </div>
    </S.Container>
  );
};

export default TravelExpertCTA;
