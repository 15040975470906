import { Dropdown } from 'antd';
import styled from 'styled-components';
import commonTexts from '../../styles/commonTexts';

export const DropdownFlag = styled(Dropdown)`
  align-items: center;
  cursor: pointer;
  .flag-icon {
    background-size: cover;
    border-radius: 4px;
    bottom: 3px;
    height: 15px;
    position: relative;
    width: 25px;
  }

  .ant-space-item {
    ${commonTexts.CommonParagraph};
    height: 25px;
  }

  .flag-menu__language {
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: 400;
    position: relative;
    bottom: 2px;
  }

  .flag-menu__language-flag {
    width: 24px;
    height: 24px;
  }

  .anticon {
    bottom: 0px;
    color: ${(props) => props.theme.colors.v2.secondary[400]};
    font-size: 15px;
    position: relative;
  }
`;
