// mentor routes
import { Route } from 'react-router-dom';

import { FindMentor } from 'pages/TravellerPages/FindMentor';
import AboutUsPage from 'pages/AboutUs';
import Experiences from 'pages/v2/experiences';
import FAQ from 'pages/faq';
import FindTravelExpert from 'pages/v2/find-travel-expert';
import ForbiddenPage from 'pages/Common/403';
import Home from 'pages/home';
import HomeV2 from 'pages/v2/home';
import MentorDetails from 'pages/TravellerPages/MentorDetails';
import Privacy from 'pages/Privacy';
import TermsConditions from 'pages/TermsConditions';

export default [
  <Route path="/home-v1" exact element={<Home />} key={'unlogged-home1'} />,
  <Route path="/" exact element={<HomeV2 />} key={'unlogged-home'} />,
  <Route path="faq" exact element={<FAQ />} key={'unlogged-faq'} />,
  <Route path="about-us" exact element={<AboutUsPage />} key={'about-us'} />,
  <Route
    path="/not-allowed"
    exact
    element={<ForbiddenPage />}
    key={'not-allowed'}
  />,
  <Route
    path="terms-and-conditions"
    exact
    element={<TermsConditions />}
    key={'unlogged-terms-and-conditions'}
  />,
  <Route
    path="privacy-policy"
    exact
    element={<Privacy />}
    key={'unlogged-privacy-policy'}
  />,
  <Route
    path="advisor-details/:id"
    exact
    element={<MentorDetails />}
    key={'unlogged-advisor-details'}
  />,
  <Route
    path="advisor-details/:country/:name/:id"
    exact
    element={<MentorDetails />}
    key={'unlogged-advisor-details-by-country'}
  />,
  <Route
    path="search-advisor"
    element={<FindMentor />}
    key={'unlogged-search-advisor'}
  />,
  <Route
    path="search-travel-expert"
    element={<FindTravelExpert />}
    key={'unlogged-search-travel-expert'}
  />,
  <Route
    path="testimonials"
    element={<Experiences />}
    key={'unlogged-experiences'}
  />,
];
