import { useState } from 'react';

function useFilterMentors() {
  const [tagFilters, setTagFilters] = useState([]);

  function toggleTagFilter(tag) {
    if (!tag) return;

    let index = tagFilters.findIndex((obj) => obj.id === tag.id);
    if (tagFilters.includes(tag.id) || index > -1) {
      setTagFilters([]);
    } else {
      setTagFilters([tag]);
    }
  }

  return {
    tagFilters,
    toggleTagFilter,
  };
}

export default useFilterMentors;
