import {
  CONTENTA_GIFT_CARD,
  CONTENTA_REDIRECT,
  CONTENTA_SELECTED_SCHEDULE,
  STATE_SCHEDULE,
} from 'constants/storages-values';
import PaymentContainer from 'pages/TravellerPages/Checkout/PaymentContainer';
import { ScheduleBriefingProvider } from 'pages/TravellerPages/Checkout/useScheduleContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './styles';

export const CheckoutGiftCard = () => {
  const { state } = useLocation();

  const dataFromStorage = JSON.parse(
    sessionStorage.getItem(CONTENTA_GIFT_CARD)
  );

  const checkoutState = {
    ...dataFromStorage,
    ...state.data,
    clientSecret: state.clientSecret,
    protocol: state.protocol,
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(STATE_SCHEDULE);
      sessionStorage.removeItem(CONTENTA_REDIRECT);
      sessionStorage.removeItem(CONTENTA_SELECTED_SCHEDULE);
      sessionStorage.removeItem(CONTENTA_GIFT_CARD);
    };
  }, []);

  return (
    <ScheduleBriefingProvider
      advisorId={
        checkoutState?.advisor?.id || checkoutState?.advisorId || 'NOT_INFORMED'
      }
      checkoutState={checkoutState}
    >
      <S.Container>
        <PaymentContainer checkoutState={checkoutState} />
      </S.Container>
    </ScheduleBriefingProvider>
  );
};

export default CheckoutGiftCard;
