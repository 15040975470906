import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Card, Skeleton } from 'antd';

import MentorCard from '../MentorCard';

import fetcher from 'services/fetcher';

import 'swiper/css';
import 'swiper/css/scrollbar';

import * as S from './styles';

const isMobile = window.innerWidth < 1024;

const ExpertsCarousel = () => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useSWR('advisor/highlights', fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 0,
    revalidateOnReconnect: false,
  });

  const slidePerView = isMobile ? 2 : 3;
  const swiperProps = {
    className: 'not-talked__mentors-card',
    modules: [Scrollbar],
    navigation: false,
    scrollbar: { draggable: true },
    slidesPerView: 'auto',
    spaceBetween: 16,
    slidePerView: data?.length > 1 ? slidePerView : data?.length,
    lazyPreloadPrevNext: 3,
  };

  if (isLoading) {
    return (
      <S.ExpertsCarouselContainer>
        <Swiper {...swiperProps}>
          {Array.from({ length: 5 }).map((_, index) => (
            <SwiperSlide key={index}>
              <Card style={{ width: 210, height: 200 }}>
                <Skeleton active />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </S.ExpertsCarouselContainer>
    );
  }

  if (error || !data) {
    return null;
  }

  return (
    <S.ExpertsCarouselContainer
      className={`${data.length === 1 ? 'single' : ''}`}
    >
      <S.Title>{t('traveler.home.meetMentors')}</S.Title>
      <Swiper {...swiperProps}>
        {data?.map((mentor, index) => (
          <>
            {index === 0 && isMobile && (
              <SwiperSlide>
                <div className="swiper-slide__empty" />
              </SwiperSlide>
            )}
            <SwiperSlide>
              <MentorCard mentor={mentor} />
            </SwiperSlide>

            {index === data.length - 1 && data.length > 1 && (
              <SwiperSlide>
                <div className="swiper-slide__empty" />
              </SwiperSlide>
            )}
          </>
        ))}
        <br />
      </Swiper>
      <div className="fade-effect"></div>
    </S.ExpertsCarouselContainer>
  );
};

export default ExpertsCarousel;
