import { useTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import { Card, Skeleton } from 'antd';

import MentorCard from 'pages/v2/home/components/MentorCard';

import { useFindExpert } from 'contexts/find-expert-context';

import * as S from './styles';
import useList from './useList';

const TravelExpertListWrapper = ({ children }) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <h2>{t('mentorList.title')}</h2>
      {children}
    </S.Container>
  );
};

const PlaceholderLoader = () => (
  <div className="travel-expert-list">
    {Array.from({ length: 8 }).map(() => (
      <Card style={{ width: 270, height: 370 }}>
        <Skeleton avatar paragraph={{ rows: 4 }} active />
      </Card>
    ))}
  </div>
);

const TravelExpertList = () => {
  const { t } = useTranslation();

  const { filters, getMoreData } = useFindExpert();
  const { expertList, isLoading, pagination } = useList();

  if (isLoading && filters.page === 0) {
    return (
      <TravelExpertListWrapper>
        <PlaceholderLoader />
      </TravelExpertListWrapper>
    );
  }

  if (!expertList?.length && !isLoading) {
    return (
      <TravelExpertListWrapper>
        <div className="travel-expert-list">
          <p>{t('traveler.historyCalls.noResults')}</p>
        </div>
      </TravelExpertListWrapper>
    );
  }

  return (
    <TravelExpertListWrapper>
      <InfiniteScroll
        dataLength={expertList.length}
        next={getMoreData}
        hasMore={!pagination?.last}
        loader={<PlaceholderLoader />}
      >
        <div className="travel-expert-list">
          {expertList?.map((expert) => (
            <MentorCard mentor={expert} />
          ))}
        </div>
      </InfiniteScroll>
    </TravelExpertListWrapper>
  );
};

export default TravelExpertList;
