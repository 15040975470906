import feedbackOne from '../assets/images/home/feedbacks/feedback1.jpeg';
import feedbackThree from '../assets/images/home/feedbacks/feedback3.jpeg';
import feedbackTwo from '../assets/images/home/feedbacks/feedback2.jpeg';
import feedbackFour from '../assets/images/home/feedbacks/feedback4.jpg';
import feedbackFive from '../assets/images/home/feedbacks/feedback5.jpg';

export const comments = [
  {
    image:
      'https://cdn.contenta360.com/review/iceland/lucas/portuguese/ff9598d9-04ed-4d8f-9318-0a2689618633.mp4',
    name: 'Lucas',
    typeUpload: 'video',
    comment:
      'For me, who was kind of lost on what to do, it was so worth it. Instantly, my (Travel Expert) gave me all of the “must see” main attractions. Then she gave me tips on transportation, security, pubs and more. It was amazing!',
    avatar: feedbackOne,
    type: 'Traveler',
  },
  {
    image: feedbackTwo,
    name: 'Allegra',
    comment:
      'Being able to speak with Contenta360 Travel Expert live really solidified our goals and intentions for this trip, but also gives us the confidence that we are making the right choices for our travel ensuring we get the best possible experience with our time and money... Cathy made this possible by knowing exactly what cities, attractions and restaurants, etc. to hit up. She was able to plan our route and give suggestions on national parks, museums, shops, and restaurants that we are so excited to try! She shared a lot about Ireland’s history, people and culture which was super unique and cool to be able to hear this from someone that is currently living in there... she spoke so passionately about her country that I am so excited to go and visit!',
    avatar: feedbackTwo,
    type: 'Traveler',
  },
  {
    image: feedbackThree,
    name: 'Octavio',
    comment: `Guys, I'm here in Japan, we've been planning this trip for a few months. Already planning for 5 to 6 months. If you add up everything we've already researched about the cities, and what to do in each one, it must have taken more than 60 hours and even so, using the contenta360 platform we received some tips that we didn't find anywhere else. Local tips are very important for us, having a rich cultural experience, like going to places where the locals go, experiencing the local culture, so it was really cool to have tips from the (Travel Expert) tips that we wouldn't find anywhere else.`,
    avatar: feedbackThree,
    type: 'Traveler',
  },
  {
    image:
      'https://cdn.contenta360.com/review/iceland/otavio/english/99c8f84c-431a-4fa6-b693-2a30681cbb3d.mp4',
    name: 'Octavio',
    typeUpload: 'video',
    comment: '',
    avatar: feedbackFour,
    type: 'Traveler',
  },
  {
    image:
      'https://cdn.contenta360.com/review/iceland/otavio/portuguese/f87a6c33-f36e-42b9-b2e7-5e2d6e211f8a.mp4',
    name: 'Manuela',
    comment: '',
    typeUpload: 'video',
    avatar: feedbackFive,
    type: 'Traveler',
  },
];
