import confirmedPayment from 'assets/images/confirmed-payment.png';
import Avatar from 'components/Avatar';
import {
  ContentaButtonOutlineWhite,
  ContentaButtonPrimaryGhost,
} from 'components/Styled/ContentaButton';
import { MEETING_TYPES } from 'constants/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmedPaymentContainer } from '../styles';
import { useScheduleBriefingContext } from '../useScheduleContext';
import { OrangeButton } from 'components/Styled/v2/OrangeButton';

function ConfirmedPayment({ isPresential }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { paidBooking } = useScheduleBriefingContext();

  const handleRedirectHome = () => {
    navigate('/home');
  };

  const handleRedirectBriefing = () => {
    navigate(`/briefing/${paidBooking}`, {
      state: {
        advisor: state?.advisor,
        schedule: state.schedule,
        selectedSchedule: state.selectedSchedule,
        schedulingType: isPresential
          ? MEETING_TYPES.presential
          : MEETING_TYPES.online,
      },
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleRedirectBriefing();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ConfirmedPaymentContainer>
      <Avatar src={confirmedPayment} alt="Pagamento confirmado" />
      <h2>{t('traveler.scheduleBriefing.confirmedPayment.title')}</h2>
      {state?.isPayingExtraTax ? (
        <p>{t('traveler.scheduleBriefing.confirmedPayment.rescheduled')}</p>
      ) : (
        <p>{t('traveler.scheduleBriefing.confirmedPayment.paragraph1')}</p>
      )}
      <OrangeButton onClick={() => handleRedirectBriefing()}>
        {t('traveler.scheduleBriefing.confirmedPayment.briefing')}
      </OrangeButton>

      <ContentaButtonPrimaryGhost
        onClick={handleRedirectHome}
        className="payment__home-btn"
      >
        {t('traveler.scheduleBriefing.confirmedPayment.button')}
      </ContentaButtonPrimaryGhost>
    </ConfirmedPaymentContainer>
  );
}

export default ConfirmedPayment;
