import { Divider, Tabs } from 'antd';
import {
  ContentaButtonPrimary,
  ContentaButtonPrimaryOutline,
} from 'components/Styled/ContentaButton';
import { ContentaModal } from 'components/Styled/ContentaModal';
import { ContentaTab } from 'components/Styled/ContentaTab';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import useGiftCardModal from './useGiftCardModal';
import GiftCardDataForm from './GiftCardDataForm';
import GiftCardValueForm from './GiftCardValueForm';

function GiftCardModal({ onCancel, visible }) {
  const { t } = useTranslation();
  const {
    currentTab,
    form,
    howToGift,
    handleGiftClickButton,
    setCurrentTab,
    setHowToGift,
    checkIsDisabled,
  } = useGiftCardModal();

  const handleCloseModal = () => {
    setCurrentTab('giftCardData');
    setHowToGift('chooseOption');
    form.resetFields();
    onCancel();
  };

  return (
    <ContentaModal
      footer={null}
      hasFooter={false}
      onCancel={handleCloseModal}
      title={t('home.giftButton')}
      open={visible}
      destroyOnClose={true}
      className="modal__tab-no-clickable"
    >
      <S.ContainerGiftCardModal>
        <ContentaTab
          activeKey={currentTab}
          onTabClick={() => setCurrentTab(currentTab)}
        >
          <Tabs.TabPane
            tab={t('giftCardModal.giftCardData')}
            key="giftCardData"
          >
            <GiftCardDataForm form={form} />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={t('giftCardModal.scheduleData')}
            key="scheduleData"
          >
            <GiftCardValueForm
              form={form}
              howToGift={howToGift}
              setHowToGift={setHowToGift}
            />
          </Tabs.TabPane>
        </ContentaTab>

        <Divider />

        <footer>
          {currentTab === 'scheduleData' && (
            <ContentaButtonPrimaryOutline
              onClick={() => setCurrentTab('giftCardData')}
            >
              {t('common.back')}
            </ContentaButtonPrimaryOutline>
          )}
          <ContentaButtonPrimary
            onClick={handleGiftClickButton}
            disabled={checkIsDisabled()}
          >
            {currentTab === 'giftCardData'
              ? t('giftCardModal.buttonSchedule')
              : howToGift === 'chooseOption'
              ? t('giftCardModal.buttonPayment')
              : t('giftCardModal.buttonChooseOption')}
          </ContentaButtonPrimary>
        </footer>
      </S.ContainerGiftCardModal>
    </ContentaModal>
  );
}

export default GiftCardModal;
