import { Button } from 'antd';
import styled from 'styled-components';

export const OrangeButton = styled(Button)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.v2.secondary[400]};
  border-radius: 10px;
  border: none;
  color: #fff;
  display: flex;
  font-size: 16px;
  height: 47px;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      theme.colors.v2.secondary[500]}!important;
    border: none;

    color: ${({ theme }) => theme.colors.v2.neutral[50]}!important;
  }
`;
