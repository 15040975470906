import { useState, useEffect } from 'react';
import { Alert, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');

    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 16,
        left: 16,
        right: 16,
        zIndex: 1000,
      }}
    >
      <Alert
        message={t('cookies.message')}
        description={t('cookies.description')}
        type="info"
        showIcon
        action={
          <Button type="primary" size="small" onClick={handleAccept}>
            {t('buttons.accept')}
          </Button>
        }
        closable
        onClose={() => setShowConsent(false)}
      />
    </div>
  );
};

export default CookieConsent;
