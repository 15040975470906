import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.v2.primary[900]};
  padding: 20px 15px;
  margin-bottom: 4px;

  .travel-expert-cta {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: ${({ theme }) => theme.containerSize};
      margin: 0 auto;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .travel-expert-cta__text {
    color: ${({ theme }) => theme.colors.v2.neutral[0]};
    font-weight: 400;
    font-size: 25px;
    max-width: 285px;
    margin: 0 0 16px;
  }

  .travel-expert-cta__button {
    max-width: 164px;
  }
`;
