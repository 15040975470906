import styled from 'styled-components';

export const BannerContainer = styled.div`
  height: 250px;
  position: relative;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 300px;
  }
`;

export const SlideContent = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  .swiper__image {
    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      border-radius: 0 0 10px 10px;
    }
  }
`;

export const Overlay = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.neutralWhite[10]};
  display: flex;
  flex-flow: column wrap;
  font-size: 30px;
  gap: 20px;
  left: 50%;
  padding: 10px 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -80%);
  width: 100%;
`;

export const TextTitle = styled.h3`
  color: ${({ theme }) => theme.colors.neutralWhite[10]};
  width: 100%;
  margin: 0;
`;

export const SwiperText = styled.span`
  color: ${({ theme }) => theme.colors.neutralWhite[10]};
  display: block;
  font-size: 20px;
  min-width: 100%;
  padding: 0;
  top: 115px;
  transform: translate(0);
  z-index: 2;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 30px;
  }
`;

export const SearchContainer = styled.div`
  align-items: center;
  background: linear-gradient(90deg, #1b778950 14.13%, #001f3e50 72.12%);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: 15px;
  max-width: 1200px;
  padding: 10px;
  position: relative;
  transform: translateY(-25%);
  z-index: 99;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-flow: row nowrap;
    height: 130px;
    margin: 0 55px;
    padding: 20px 10px 20px 20px;
    transform: translateY(-65%);
  }

  &.search-bar__filtering {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin: 0 auto;
    }
  }
`;

export const SearchField = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralWhite[10]};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-right: 0;
    height: 90px;
  }

  &.search-field__input {
    &:first-child {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        max-width: 290px;

        .css-13cymwt-control {
          top: -8px;
        }
      }
    }
  }

  &.search-field--radio {
    border-radius: 10px 10px 0 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      border-radius: 10px 0 0 10px;
    }
  }

  .radio-group {
    padding: 0 20px 16px;
  }

  .ant-radio-group {
    padding: 0 0 0 20px;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  margin-bottom: 5px;
  padding: 20px 20px 8px;
`;

export const StyledInput = styled.div`
  .css-13cymwt-control {
    border: none !important;
    border-radius: 10px;
    padding: 0 16px;
  }
`;

export const ButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralWhite[10]};
  border-radius: 0 0 10px 10px;
  display: flex;
  left: -10px;
  padding: 20px;
  position: relative;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: center;
    border-radius: 0 10px 10px 0;
    display: flex;
    height: 100%;
    justify-content: center;
    max-height: 90px;
    padding: 0 20px 0 0;
    width: auto;

    button {
      max-width: 120px;
    }
  }
`;
