import { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined } from '@ant-design/icons';

import { ContentaInput } from 'components/Styled/ContentaInput';
import { OrangeButton } from 'components/Styled/v2/OrangeButton';
import ContentaLogo from 'components/Icons/ContentaLogo';

import { useLanguage } from 'contexts/languageContext';

import ShareExperiences from './ShareExperience';
import TravelExpertCTA from './TravelExpertCTA';

import cadasturLogo from '../../../assets/images/logos/cadastur.webp';

import * as S from './styles';

const EmailIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.0217 19.805 20.5507 20.0007 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z"
      fill="white"
    />
  </svg>
);

const YoutubeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.54 6.42C22.4151 5.95228 22.1698 5.52547 21.8285 5.1821C21.4872 4.83873 21.0619 4.59079 20.595 4.463C18.88 4 12 4 12 4C12 4 5.12 4 3.405 4.463C2.93806 4.59079 2.51276 4.83873 2.1715 5.1821C1.83023 5.52547 1.58492 5.95228 1.46 6.42C1 8.148 1 11.75 1 11.75C1 11.75 1 15.352 1.46 17.08C1.58476 17.5479 1.83001 17.9749 2.17129 18.3185C2.51256 18.662 2.93794 18.9101 3.405 19.038C5.121 19.5 12 19.5 12 19.5C12 19.5 18.88 19.5 20.595 19.038C21.0621 18.9101 21.4874 18.662 21.8287 18.3185C22.17 17.9749 22.4152 17.5479 22.54 17.08C23 15.354 23 11.75 23 11.75C23 11.75 23 8.148 22.54 6.42ZM9.75 15.021V8.48L15.5 11.751L9.75 15.021Z"
      stroke="#F6F6F6"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

const InstagramIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8473 24H5.15271C2.31354 24 0 21.6865 0 18.8473V5.15271C0 2.31354 2.31354 0 5.15271 0H18.8473C21.6865 0 24 2.31354 24 5.15271V18.8473C24 21.6949 21.6949 24 18.8473 24ZM5.15271 22.2857H18.8473C20.7481 22.2857 22.2857 20.7481 22.2857 18.8473V5.15271C22.2857 3.26031 20.7397 1.71429 18.8473 1.71429H5.15271C3.26031 1.71429 1.71429 3.26031 1.71429 5.15271V18.8473C1.71429 20.7397 3.26031 22.2857 5.15271 22.2857Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48184 14.5261C10.157 15.2012 11.0472 15.5701 12.0038 15.5701C12.955 15.5701 13.8399 15.2049 14.5283 14.5236C15.2018 13.8488 15.5696 12.9596 15.5696 12.0042C15.5696 11.0476 15.2008 10.1574 14.5257 9.48227C13.8506 8.80715 12.9604 8.43835 12.0038 8.43835C11.0472 8.43835 10.157 8.80715 9.48184 9.48227C8.80672 10.1574 8.43792 11.0476 8.43792 12.0042C8.43792 12.9608 8.80672 13.851 9.48184 14.5261L8.26966 15.7383C7.27277 14.7414 6.72363 13.4151 6.72363 12.0042C6.72363 10.5933 7.27277 9.26698 8.26966 8.27009C9.26655 7.27319 10.5929 6.72406 12.0038 6.72406C13.4146 6.72406 14.741 7.27319 15.7379 8.27009C16.7348 9.26698 17.2839 10.5933 17.2839 12.0042C17.2839 13.4151 16.7348 14.7414 15.7379 15.7383C14.7326 16.7352 13.4146 17.2843 12.0038 17.2843C10.5929 17.2843 9.26655 16.7352 8.26966 15.7383L9.48184 14.5261Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3135 4.95152C17.9903 4.95152 17.7283 5.21354 17.7283 5.53676C17.7283 5.85997 17.9903 6.12199 18.3135 6.12199C18.6367 6.12199 18.8987 5.85997 18.8987 5.53676C18.8987 5.21354 18.6367 4.95152 18.3135 4.95152ZM16.8711 5.53676C16.8711 4.74014 17.5169 4.09436 18.3135 4.09436C19.1101 4.09436 19.7559 4.74014 19.7559 5.53676C19.7559 6.33337 19.1101 6.97915 18.3135 6.97915C17.5169 6.97915 16.8711 6.33337 16.8711 5.53676Z"
      fill="white"
    />
  </svg>
);

const FacebookIcon = () => (
  <svg
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2358 13.6813H8.95689V22.2857H5.00084V13.6737H1.71429V10.1817H5.00084V8.91124C5.00084 4.19442 6.97126 1.71429 11.1403 1.71429C12.1185 1.71429 12.5352 1.83459 12.9906 1.96609C13.1303 2.00641 13.2736 2.04779 13.4379 2.08707V5.541C13.2895 5.51508 13.1614 5.49134 13.0456 5.46988C12.5764 5.38296 12.3095 5.33352 11.7236 5.32842C11.69 5.32813 11.6554 5.32798 11.6196 5.32798C10.6534 5.32798 10.1361 5.60186 9.66441 6.14201C9.25151 6.61485 9.01933 7.39083 8.96788 8.47506C8.96055 8.62942 8.95689 8.79002 8.95689 8.95689V10.1893H13.4379L12.2358 13.6813ZM10.6846 8.47506H13.4379C13.9919 8.47506 14.5117 8.74279 14.8335 9.1938C15.1552 9.6448 15.2391 10.2235 15.0588 10.7473L13.8568 14.2393C13.6186 14.9312 12.9676 15.3956 12.2358 15.3956H10.6712V22.2857C10.6712 23.2325 9.90366 24 8.95689 24H5.00084C4.05407 24 3.28656 23.2325 3.28656 22.2857V15.388H1.71429C0.767512 15.388 0 14.6205 0 13.6737V10.1817C0 9.23497 0.767512 8.46746 1.71429 8.46746H3.292C3.35092 6.09409 3.8942 3.99298 5.19329 2.44284C6.62164 0.738463 8.70564 0 11.1403 0C12.381 0 13.0378 0.191838 13.5135 0.330777C13.6301 0.364825 13.7358 0.395696 13.8366 0.419789C14.608 0.604264 15.1522 1.29388 15.1522 2.08707V5.541C15.1522 6.04727 14.9284 6.52766 14.5408 6.85338C14.1532 7.1791 13.6415 7.31684 13.1428 7.22969C12.965 7.19863 12.8225 7.17235 12.7025 7.15022C12.2503 7.06683 12.1171 7.04227 11.6196 7.04227C11.296 7.04227 11.1768 7.08844 11.147 7.10155C11.1279 7.10993 11.0746 7.13337 10.9557 7.26959C10.9165 7.31446 10.7356 7.6112 10.6846 8.47506Z"
      fill="white"
    />
  </svg>
);

export function FooterApp() {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isInPortuguese = currentLanguage === 'pt-BR';

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsSubscribed(true);
    }, 2000);
  };

  return (
    <>
      <ShareExperiences />
      <TravelExpertCTA />
      <S.FooterContainer>
        <div className="footer__container">
          <div className="footer__contact">
            <div className="footer__address">
              <ContentaLogo />

              <address>
                <span>
                  Alameda Lorena, 131 - Cj 31 Jardim Paulista - São Paulo - SP
                  01.424-000
                </span>
              </address>

              <a href="tel:+5511999999999">+55 11 9 9999-9999</a>
              <a href="mailto:contactus@contenta360.com">
                contactus@contenta360.com
              </a>
            </div>

            <div className="footer__form">
              <form
                action="https://contenta360.us14.list-manage.com/subscribe/post?u=b048b708c7bfa536d0867d9fc&id=5e6f6bbbea"
                method="POST"
                target="_blank"
              >
                <ContentaInput
                  type="text"
                  name="FNAME"
                  placeholder={t('footer.name')}
                  required
                  className="border p-2 rounded w-full"
                />
                <ContentaInput
                  type="email"
                  name="EMAIL"
                  placeholder={t('footer.email')}
                  required
                  className="border p-2 rounded w-full mt-2"
                />
                <OrangeButton
                  type="submit"
                  className="ant-btn newsletter__button"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <LoadingOutlined /> : null}

                  {t('footer.subscribe')}
                </OrangeButton>
              </form>
            </div>
          </div>

          <div className="footer__info">
            <div
              className={`footer__links ${
                isInPortuguese ? 'footer__links--brazil' : ''
              }`}
            >
              <Link to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
              <span className="footer__links-divider">|</span>
              <Link to="/terms-and-conditions">
                {t('footer.termsConditions')}
              </Link>
            </div>

            <div className="footer__divider" />

            <div className="footer__social-icons">
              <Link
                to="mailto:contactus@contenta360.com"
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCp4llvJRbRzmcWzvONE02iA"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeIcon />
              </Link>
              <Link
                to="https://www.instagram.com/contenta360/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </Link>
              <Link
                to="https://facebook.com/contenta360"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </Link>
            </div>
          </div>

          <div className="footer__affiliates">
            <Link
              href="https://cadastur.turismo.gov.br/hotsite/#!/public/capa/entrar"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={cadasturLogo}
                alt="cadastur logo"
                style={{ width: '120px' }}
              />
            </Link>
          </div>
        </div>
      </S.FooterContainer>
    </>
  );
}
