/* eslint-disable prettier/prettier */
import { Button } from 'antd';
import styled, { css } from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

const getButtonHeight = (size = 'medium') => {
  switch (size) {
    case 'large':
      return convertPxToRem(52);
    case 'medium':
      return convertPxToRem(48);
    case 'small':
      return convertPxToRem(32);
    // file deepcode ignore DuplicateCaseBody: Default case
    default:
      return convertPxToRem(48);
  }
};

export const ContentaButton = styled(Button)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralBlack[40]};
  border-radius: 8px;
  border: none!important;
  color: ${({ theme }) => theme.colors.neutralWhite[10]};
  display: flex;
  flex-direction: row;
  font-weight: 800;
  gap: 8px;
  height: ${({ size }) => getButtonHeight(size)}!important;
  justify-content: center;
  padding: 16px;
  will-change: background-color, border, color;

  span {
    align-items: center;
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    display: flex;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(14)};
    font-style: normal;
    font-weight: 700;
    gap: 8px;
    line-height: 14px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${convertPxToRem(16)};
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.colors.neutralBlack[30]}!important;
    border: 2px solid ${({ theme }) => theme.colors.neutralBlack[30]}!important;

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]}!important;

      svg {
        path,
        stroke {
          fill: ${({ theme }) => theme.colors.neutralWhite[10]};
        }
      }
    }
  }

  svg {
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.neutralWhite[10]};
    }
  }

  > span + .anticon {
    margin-left: 0;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.neutralWhite[30]};
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.neutralWhite[30]}!important;
      border: 2px solid
        ${({ theme }) => theme.colors.neutralWhite[30]}!important;
    }

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }

    svg {
      path,
      stroke {
        fill: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
    }
  }

  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.neutralBlack[30]};
    border: 2px solid ${({ theme }) => theme.colors.neutralWhite[40]}!important;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.neutralWhite[10]};
    outline: none;
  }
`;

export const ContentaButtonOutline = styled(ContentaButton)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.neutralBlack[30]}!important;
  color: ${({ theme }) => theme.colors.neutralBlack[40]};
  span {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
  }

  svg {
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.neutralBlack[40]};
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutralBlack[30]};
    border: 2px solid ${({ theme }) => theme.colors.neutralBlack[30]}!important;

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]}!important;

      svg {
        path,
        stroke {
          fill: ${({ theme }) => theme.colors.neutralWhite[10]};
        }
      }
    }
  }
`;

export const ContentaButtonOutlineWhite = styled(ContentaButtonOutline)`
  border: 2px solid ${({ theme }) => theme.colors.neutralWhite[10]}!important;
  color: ${({ theme }) => theme.colors.neutralWhite[10]};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutralWhite[10]}!important;
    border: 2px solid ${({ theme }) => theme.colors.neutralWhite[10]}!important;

    span {
      color: ${({ theme }) => theme.colors.v2.secondary[400]};
    }
  }

  .ant-btn {
    align-items: center;
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    display: flex;
    justify-content: center;
  }

`;

export const ContentaButtonPrimary = styled(ContentaButton)`
  background-color: ${({ theme }) => theme.colors.v2.secondary[400]};
  border-radius: 10px;
  border: none;
  font-size: 16px;
  height: 47px;
  padding: 10px 20px;
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      theme.colors.v2.secondary[500]}!important;
    border: none!important;
  }

  &.--white {
    background-color: ${({ theme }) => theme.colors.neutralWhite[10]};
    border: 2px solid ${({ theme }) => theme.colors.neutralWhite[10]}!important;
    span {
      color: ${({ theme }) => theme.colors.v2.secondary[400]};
    }
    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.neutralWhite[10]}!important;
      border: 2px solid
        ${({ theme }) => theme.colors.neutralWhite[10]}!important;
    }
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.v2.secondary[200]};
    border: 2px solid transparent !important;
    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }
    svg {
      path,
      stroke {
        fill: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
    }

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.v2.secondary[200]}!important;
      border: 2px solid transparent !important;
    }
  }

  ${({ isMentor }) =>
    isMentor &&
    css`
      background-color: ${({ theme }) => theme.colors.blue[40]};
      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.colors.blue[50]}!important;
      }

      &[disabled] {
        background-color: ${({ theme }) => theme.colors.blue[30]};
        border: 2px solid transparent !important;
        &:hover,
        &:focus {
          background-color: ${({ theme }) => theme.colors.blue[30]}!important;
          border: 2px solid transparent !important;
        }
        span {
          color: ${({ theme }) => theme.colors.neutralWhite[10]};
        }
      }
    `};
`;

export const ContentaButtonPrimaryOutline = styled(ContentaButtonPrimary)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.v2.secondary[400]}!important;

  span {
    color: ${({ theme }) => theme.colors.v2.secondary[400]};
  }

  svg {
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.v2.secondary[400]};
    }
  }

  &.--white {
    border: 2px solid ${({ theme }) => theme.colors.neutralWhite[10]}!important;
    background-color: transparent;

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }
    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.neutralWhite[10]}!important;

      span {
        color: ${({ theme }) => theme.colors.neutralBlack[40]};
      }
    }
  }

  &.--black {
    border: 2px solid ${({ theme }) => theme.colors.neutralBlack[10]}!important;
    background-color: transparent;

    span {
      color: ${({ theme }) => theme.colors.neutralBlack[10]};
    }
    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.neutralWhite[10]}!important;
      span {
        color: ${({ theme }) => theme.colors.neutralWhite[40]};
      }
    }
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.v2.secondary[300]}!important;
    border: 2px solid ${({ theme }) => theme.colors.v2.secondary[300]}!important;

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }

    svg {
      path,
      stroke {
        fill: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
    }
  }

  // disabled
  &[disabled] {
    background-color: ${({ theme }) => theme.colors.v2.secondary[200]};
    border: 2px solid ${({ theme }) => theme.colors.v2.secondary[200]}!important;
    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }
    svg {
      path,
      stroke {
        fill: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
    }

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.v2.secondary[200]}!important;
      border: 2px solid ${({ theme }) => theme.colors.v2.secondary[200]}!important;
      span {
        color: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
      svg {
        path,
        stroke {
          fill: ${({ theme }) => theme.colors.neutralWhite[10]};
        }
      }
    }
  }
`;

export const ContentaButtonPrimaryGhost = styled(ContentaButtonPrimary)`
  background-color: transparent;
  border: 2px solid transparent !important;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: transparent !important;

    span {
      text-decoration: underline;
    }
  }
`;

export const ContentaButtonBlackGhost = styled(ContentaButtonPrimary)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.neutralBlack[10]}!important;
  box-shadow: none;
  span {
    color: ${({ theme }) => theme.colors.neutralBlack[10]};
  }

  svg {
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.neutralBlack[10]};
    }
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutralBlack[20]}!important;

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};

      svg > path stroke {
        fill: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
    }
  }
`;

export const ContentaButtonSecondary = styled(ContentaButton)`
  background-color: ${({ theme }) => theme.colors.purple[30]};
  border: 1px solid transparent !important;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.purple[40]}!important;
    border: 1px solid ${({ theme }) => theme.colors.purple[40]}!important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.purple[10]}!important;
    border: 1px solid ${({ theme }) => theme.colors.purple[10]}!important;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.purple[10]}!important;
      border: 1px solid ${({ theme }) => theme.colors.purple[10]}!important;
    }
  }
`;

export const ContentaButtonSecondaryOutline = styled(ContentaButtonSecondary)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.purple[30]}!important;
  span {
    color: ${({ theme }) => theme.colors.purple[30]};
  }
  svg {
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.purple[30]};
    }
  }
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.purple[30]}!important;
    border: 2px solid ${({ theme }) => theme.colors.purple[30]}!important;
    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }
    svg {
      path,
      stroke {
        fill: ${({ theme }) => theme.colors.neutralWhite[10]};
      }
    }
  }
`;

export const ContentaButtonDanger = styled(ContentaButton)`
  background-color: ${({ theme }) => theme.colors.yellow[40]};
  border: 1px solid transparent !important;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.error[40]}!important;
    border: 1px solid ${({ theme }) => theme.colors.error[40]}!important;
  }

  // disabled
  &[disabled] {
    cursor: not-allowed !important;
    background-color: ${({ theme }) => theme.colors.yellow[40]}!important;
    border: 1px solid ${({ theme }) => theme.colors.yellow[40]}!important;
    opacity: 0.5;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.yellow[40]}!important;
      border: 1px solid ${({ theme }) => theme.colors.yellow[40]}!important;
    }
  }
`;

export const ContentaButtonDangerOutline = styled(ContentaButtonDanger)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.yellow[40]}!important;
  span {
    color: ${({ theme }) => theme.colors.yellow[40]};
  }
`;

export const ContentaButtonSuccess = styled(ContentaButton)`
  background-color: ${({ theme }) => theme.colors.success[30]};
  border: 1px solid transparent !important;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.success[30]}!important;
    border: 1px solid ${({ theme }) => theme.colors.success[30]}!important;
  }
`;

export const ContentaButtonWhite = styled(ContentaButton)`
  background-color: ${({ theme }) => theme.colors.neutralWhite[10]};
  border: 1px solid transparent !important;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutralWhite[30]}!important;
    border: 1px solid ${({ theme }) => theme.colors.neutralWhite[30]}!important;

    span {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
    }

    svg {
      path,
      stroke {
        fill: ${({ theme }) => theme.colors.neutralBlack[40]}!important;
      }
    }
  }

  span {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
  }

  svg {
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.neutralBlack[40]};
    }
  }
`;
