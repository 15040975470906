import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import { Tooltip } from 'antd';
import { Rating } from '@smastrom/react-rating';

import { OrangeButton } from 'components/Styled/v2/OrangeButton';

import useRedirectToAdvisorPage from 'hooks/useRedirectToAdvisorPage';

import findCountryNameById from 'utils/findCountryNameById';

import { FlagsCodeImage } from 'constants/flag-code';
import { customStarsStyles } from 'constants/stars-colors';

import bgImg from 'assets/images/default-city.webp';

import * as S from './styles';

function MentorCard({ mentor }) {
  const { t } = useTranslation();
  const { redirectToAdvisorPage } = useRedirectToAdvisorPage();

  const handleRedirectToMentor = () => {
    redirectToAdvisorPage(mentor);
  };

  const coverPhotoBg = mentor?.coverURL || mentor?.coverUrl || bgImg;

  let countryName;
  if (mentor?.addressCountry?.acronym) {
    countryName = findCountryNameById({
      acronym: mentor?.addressCountry?.acronym,
    });
  } else {
    countryName = mentor?.country;
  }

  const avatarImg = mentor?.imageUrl || mentor?.imageURL;

  const renderSpecialties = () => {
    const specialties = mentor?.topics?.slice(0, 5) || [];

    const specialtiesString = specialties
      .map((topic) => t(`specialties.${topic.name}`))
      .join(' | ');

    const specialtiesStringLength = specialtiesString.length;
    const specialtiesStringMaxLength = 50;
    const specialtiesStringTruncated =
      specialtiesStringLength > specialtiesStringMaxLength
        ? `${specialtiesString.slice(0, specialtiesStringMaxLength)}...`
        : specialtiesString;
    return (
      <div className="mentor-card__specialties-options">
        <span className="mentor-card__specialties-text">
          {specialtiesStringTruncated}
        </span>
      </div>
    );
  };

  const renderCountry = ({ slice }) => {
    const maxLengthCity = 15;
    const maxLengthCountry = 20;
    if (mentor?.city) {
      const cityName =
        slice && mentor?.city.length > maxLengthCity
          ? `${mentor?.city.slice(0, maxLengthCity)}...`
          : mentor?.city;
      const country =
        slice && countryName.length > maxLengthCountry
          ? `${countryName?.slice(0, maxLengthCountry)}...`
          : countryName;
      return (
        <span className="mentor-card__country">
          {cityName} - {country || t('home.countryNotInformed')}
        </span>
      );
    }
    if (countryName) {
      const country =
        slice && countryName.length > maxLengthCountry
          ? `${countryName?.slice(0, maxLengthCountry)}...`
          : countryName;
      return (
        <span className="mentor-card__country">
          {country || t('home.countryNotInformed')}
        </span>
      );
    }
    return (
      <span className="mentor-card__country">
        {t('home.countryNotInformed')}
      </span>
    );
  };

  return (
    <S.MentorCardContainer className="expert-card">
      <div
        className="mentor-card__cover-photo"
        style={{ backgroundImage: `url(${coverPhotoBg})` }}
      />
      <header className="mentor-card__header" onClick={handleRedirectToMentor}>
        <div
          className="mentor-card__avatar"
          style={{
            backgroundImage: `url(${avatarImg})`,
          }}
        />

        <div className="mentor-card__info">
          <Tooltip title={renderCountry({ slice: false })}>
            <h2 className="mentor-card__country">
              {renderCountry({ slice: true })}
            </h2>
          </Tooltip>
          <h3 className="mentor-card__name">{mentor?.name}</h3>
        </div>
      </header>

      <div className="mentor-card__body">
        <div className="mentor-card__idioms">
          <span className="mentor-card__idioms-text">
            {t('common.idioms')}:
          </span>
          <span className="mentor-card__idioms-options">
            {mentor?.languages?.map(({ code }) => (
              <Tooltip title={t(`languages.code.${code}`)} key={uuidv4()}>
                {FlagsCodeImage[code]}
              </Tooltip>
            ))}
          </span>
        </div>

        <div className="mentor-card__rating">
          <span className="mentor-card__idioms-text">
            {t('mentorProfile.rating')}
          </span>
          <Rating
            className="evaluate-modal__rate-stars"
            spaceBetween={4}
            style={{ maxWidth: 88 }}
            value={mentor?.rating || 0}
            isDisabled
            itemStyles={customStarsStyles}
          />
        </div>
      </div>

      <div className="mentor-card__button">
        <OrangeButton
          onClick={handleRedirectToMentor}
          className="mb-16"
          size="small"
        >
          {t('mentorProfile.expertPage')}
        </OrangeButton>
      </div>

      <div className="mentor-card__specialties">
        <span className="mentor-card__specialties-text">
          {t('mentorProfile.specialties')}:
        </span>
        {renderSpecialties()}
      </div>
    </S.MentorCardContainer>
  );
}

export default MentorCard;
