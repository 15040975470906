import { Button } from 'antd';
import styled from 'styled-components';

export const WhiteButton = styled(Button)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralWhite[10]};
  border-radius: 10px;
  border: none;
  color: ${({ theme }) => theme.colors.v2.primary[600]};
  display: flex;
  font-size: 16px;
  height: 47px;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutralWhite[30]}!important;
    border: none;
    color: ${({ theme }) => theme.colors.v2.primary[600]}!important;
  }
`;
