import styled from 'styled-components';
import commonTexts from 'styles/commonTexts';
import convertPxToRem from 'utils/convert-px-rem';

export const NewsletterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralWhite[40]};
  padding: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 32px 48px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(28)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
  }

  .newsletter__form {
    display: flex;
    flex-flow: column wrap;
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-flow: row nowrap;
      height: 56px;
    }
  }

  input {
    background: ${({ theme }) => theme.colors.neutralWhite[10]};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.neutralBlack[30]};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 400;
    height: 48px;
    letter-spacing: -0.02em;
    padding: 16px 32px;
    width: 100%;
    ::placeholder {
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 16px;
    }
  }

  .newsletter__button {
    background-color: ${({ theme }) => theme.colors.v2.secondary[400]};
    border-radius: 8px;
    border: 2px solid transparent !important;
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    width: 220px;

    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.v2.secondary[300]}!important;
      border: 2px solid transparent !important;
    }
  }

  p {
    ${commonTexts.CommonParagraph};
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-size: ${convertPxToRem(14)};
    font-weight: 500;
  }
`;
