import { OrangeButton } from 'components/Styled/v2/OrangeButton';

import * as S from './styles';

const AirPlaneIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4701 29.084L7.81257 22.1785L0.87207 18.486L2.76732 16.6012L8.80832 17.6285L14.1458 12.291L1.01732 6.6875L3.44632 4.2725L19.4151 7.01125L24.8751 1.518C25.3651 1.02683 25.9659 0.78125 26.6776 0.78125C27.3892 0.78125 27.9901 1.02683 28.4801 1.518C28.9724 2.00917 29.2186 2.60883 29.2186 3.317C29.2186 4.02517 28.973 4.62483 28.4818 5.116L22.9781 10.5848L25.7186 26.5448L23.2791 28.9842L17.6983 15.8558L12.3608 21.1932L13.3636 27.1905L11.4701 29.084Z"
      fill="#003B5B"
    />
  </svg>
);

const ShareExperiences = () => {
  const isMobile = window.innerWidth < 1024;
  return (
    <S.Container>
      <div className="share-experiences__container">
        <div className="share-experiences__content">
          <AirPlaneIcon />
          <p className="share-experiences__content-text">
            Share your experience {isMobile && <br />}
            with{' '}
            <span className="share-experiences__contenta">Contenta360</span>
          </p>
        </div>

        <OrangeButton>Share my experience</OrangeButton>
      </div>
    </S.Container>
  );
};

export default ShareExperiences;
