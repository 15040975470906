import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { INTERVAL_TIMES } from 'constants/interval-times';

const usePageTitle = () => {
  const { t } = useTranslation();

  useEffect(() => {
    let timeout;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        timeout = setTimeout(() => {
          document.title = `(1) ${t('common.comeBackAndFinish')}`;
        }, INTERVAL_TIMES.FIVE_MINUTES);
      } else {
        clearTimeout(timeout);
        document.title = 'Contenta 360';
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default usePageTitle;
