import styled from 'styled-components';

export const Container = styled.div`
  max-width: ${({ theme }) => theme.containerSize};
  margin: 0 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 auto;
  }
`;
