import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SearchOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { OrangeButton } from 'components/Styled/v2/OrangeButton';

import { useFindExpert } from 'contexts/find-expert-context';

import { CONTENTA_GIFT_CARD } from 'constants/storages-values';
import { MEETING_TYPES } from 'constants/types';

import * as S from './styles';

const SearchBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onChangeFilter, searchPage, isSearchPage, filters } = useFindExpert();

  const [searchedPlace, setSearchedPlace] = useState(null);

  const [formValues, setFormValues] = useState({
    travel_destination: filters?.travel_destination || '',
    scheduling_type: filters?.scheduling_type || MEETING_TYPES.online,
    topic: filters?.topic || null,
    page: filters?.page || 0,
    limit: filters?.limit || 10,
  });

  const handleSearch = () => {
    sessionStorage.removeItem(CONTENTA_GIFT_CARD);

    onChangeFilter({
      ...formValues,
      scheduling_type: formValues.scheduling_type || MEETING_TYPES.online,
    });

    if (isSearchPage) return;

    navigate(searchPage);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <S.SearchContainer>
      <S.SearchField className="search-field__input">
        <S.Label>{t('traveler.home.myTravelDestiny')}</S.Label>
        <S.StyledInput className="google-input home">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            selectProps={{
              placeholder:
                searchedPlace || t('traveler.home.preferredDestinyPlaceholder'),
              onChange: (value) => {
                setSearchedPlace(value);
                handleChange({
                  target: {
                    name: 'travel_destination',
                    value: value.value.place_id,
                  },
                });
              },
            }}
          />
        </S.StyledInput>
      </S.SearchField>

      <S.SearchField className="search-field__input search-field--radio">
        <S.Label>{t('traveler.home.meetingMentor')}</S.Label>
        <Radio.Group
          value={formValues?.scheduling_type || MEETING_TYPES.online}
          onChange={(e) =>
            handleChange({
              target: { name: 'scheduling_type', value: e.target.value },
            })
          }
        >
          <Radio value={MEETING_TYPES.online}>
            {t('traveler.home.virtual')}
          </Radio>
          <Radio value={MEETING_TYPES.presential}>
            {t('traveler.home.inPerson')}
          </Radio>
        </Radio.Group>
      </S.SearchField>

      <S.ButtonContainer>
        <OrangeButton
          onClick={handleSearch}
          block
          icon={<SearchOutlined style={{ fontSize: '20px' }} />}
        >
          {t('common.find')}
        </OrangeButton>
      </S.ButtonContainer>
    </S.SearchContainer>
  );
};

export default SearchBox;
