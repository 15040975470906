import { useUser } from 'contexts/userContext';
import { useNavigate } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';
import { isUserAdmin, isUserGuide } from 'utils/isRole';

const useNavbar = () => {
  const { userRole, whoAmI } = useUser();
  const navigate = useNavigate();
  const hasUser = Object.keys(whoAmI).length > 0;
  const isRegistered = checkIsRegistered({ whoAmI });

  const handleBeTravelAdvisor = () => {
    navigate('/guide/register');
  };

  function checkURLRedirect() {
    if (hasUser && isRegistered) {
      return isUserGuide(userRole) || isUserAdmin(userRole)
        ? '/advisor/home'
        : '/home';
    } else if (hasUser && !isRegistered) {
      return '/register';
    } else {
      return '/';
    }
  }

  return {
    checkURLRedirect,
    handleBeTravelAdvisor,
  };
};

export default useNavbar;
