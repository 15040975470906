import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { Dropdown } from 'antd';

import useNavbar from '../Navbar/useNavbar';

import { useLanguage } from 'contexts/languageContext';
import { useUser } from 'contexts/userContext';

import { APP_LANGUAGES } from 'constants/app-languages';

import {
  BrazilFlag,
  ContentaLogoV2,
  MenuIcon,
  TravelerIcon,
  TravelExperIcon,
  USAFlag,
  UserIcon,
} from './icons';

import './styles.scss';

export function NavbarV2({ showDrawer }) {
  const { whoAmI, user } = useUser();
  const { currentLanguage, handleChangeLanguage } = useLanguage();
  const { checkURLRedirect } = useNavbar();

  const hasUser = Object.keys(whoAmI).length > 0;

  const handleSelectLanguage = (selectLanguage) => {
    const languageObject = APP_LANGUAGES.find(
      (language) => language.key === selectLanguage
    );
    handleChangeLanguage(languageObject?.key);
  };

  const items = [
    {
      label: (
        <div className="d-flex-row gap-8">
          <USAFlag size={20} />
          {t('common.languages.en-US')}
        </div>
      ),
      key: 'en-US',
    },
    {
      label: (
        <div className="d-flex-row gap-8">
          <BrazilFlag size={20} />
          {t('common.languages.pt-BR')}
        </div>
      ),
      key: 'pt-BR',
    },
  ];

  const userItems = [
    {
      label: (
        <Link className="d-flex-row gap-8" to="/login/advisor">
          <TravelExperIcon />
          {t('buttons.loginAdvisor')}
        </Link>
      ),
      key: 'login-advisor',
    },
    {
      label: (
        <Link className="d-flex-row gap-8" to="/login">
          <TravelerIcon />
          {t('buttons.loginTraveler')}
        </Link>
      ),
      key: 'login-client',
    },
  ];

  const renderFlag = () => {
    if (currentLanguage === 'pt-BR' || currentLanguage === 'PT-BR') {
      return <BrazilFlag size={32} />;
    }
    return <USAFlag size={32} />;
  };

  const onClickChangeLanguage = (i) => {
    handleSelectLanguage(i.key);
  };

  console.log('userRole', user);

  return (
    <div className="contenta-header">
      <div className="header__content">
        <div className="header__content-leftside">
          <button
            className="trigger header__button"
            onClick={showDrawer}
            type="button"
          >
            <MenuIcon />
          </button>
          <Link
            to={checkURLRedirect()}
            className="header__content-logo-container"
          >
            <ContentaLogoV2 />
          </Link>
        </div>

        <div className="header__content-rightside">
          <Dropdown
            menu={{
              items,
              onClick: onClickChangeLanguage,
            }}
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()}>{renderFlag()}</a>
          </Dropdown>

          <Dropdown
            menu={{
              items: userItems,
              onClick: onClickChangeLanguage,
            }}
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()}>
              {!hasUser ? (
                <UserIcon />
              ) : (
                <div className="header__content-user-container">
                  <img
                    src={whoAmI?.imageURL || whoAmI?.picture}
                    alt="Avatar"
                    className="header__content-avatar"
                  />
                  <div className="header__content-user">
                    <span className="header__content-username">
                      {user?.name}
                    </span>
                    <span className="header__content-email">
                      {user?.email?.length > 20
                        ? user?.email?.substring(0, 20) + '...'
                        : user?.email}
                    </span>
                  </div>
                </div>
              )}
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
