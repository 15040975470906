import { useTranslation } from 'react-i18next';
import { Badge, Skeleton } from 'antd';

import { useMentor } from '../mentorContext';

import * as S from './styles';

const MentorSpecialties = () => {
  const { t } = useTranslation();
  const { loading, mentor } = useMentor();

  if (loading) {
    return (
      <S.MentorSpecialtiesContainer>
        <Skeleton active size="small" />
        <Skeleton active size="small" />
        <Skeleton active size="small" />
      </S.MentorSpecialtiesContainer>
    );
  }

  const { specialties, customSpecialty } = mentor;

  let customSpecialtyList = customSpecialty?.specialty?.split(',');

  customSpecialtyList =
    customSpecialtyList?.filter((specialty) => specialty) || [];

  customSpecialtyList = customSpecialtyList.map((specialty) =>
    specialty.trim()
  );

  if (!specialties?.length && !customSpecialty?.length) {
    return null;
  }

  function renderCustomSpecialty(specialty) {
    if (specialty) {
      return specialty;
    }

    return null;
  }

  const renderSpecialty = (specialty) => {
    const specialtyInfo = specialties?.find(
      ({ code }) => code === specialty.code
    );

    if (specialtyInfo) {
      return t(`specialties.${specialtyInfo.name}`);
    }

    return t(`specialties.notFound`);
  };

  return (
    <S.MentorSpecialtiesContainer>
      {specialties?.map((specialty) => (
        <Badge
          count={renderSpecialty(specialty)}
          className="mentor-tab__badge"
        />
      ))}
      {customSpecialtyList.map((specialty) => (
        <Badge
          count={renderCustomSpecialty(specialty)}
          className="mentor-tab__badge"
        />
      ))}
    </S.MentorSpecialtiesContainer>
  );
};

export default MentorSpecialties;
