import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';

import { useFindExpert } from 'contexts/find-expert-context';

import { colors } from 'styles/themes/default';

import FILTERS_OPTIONS from 'constants/filters-options';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import * as S from './styles';

const isMobile = window.innerWidth <= 1024;

const swiperProps = {
  modules: isMobile ? [Navigation, Pagination] : [],
  spaceBetween: isMobile ? 30 : 10,
  slidesPerView: isMobile ? 5 : 14,
  pagination: isMobile
    ? {
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 2,
      }
    : false,
  navigation: isMobile
    ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    : false,
};

const getIcon = (code) => FILTERS_OPTIONS[code] || 'no icon';

const SearchIcons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    filters,
    isSearchPage,
    onChangeFilter,
    searchPage,
    topics,
    topicsError,
    topicsLoading,
  } = useFindExpert();

  const handleClick = ({ code }) => {
    if (isSearchPage && code === filters.topic) {
      onChangeFilter({
        ...filters,
        topic: '',
      });
      return;
    }

    onChangeFilter({
      ...filters,
      topic: code,
    });

    if (isSearchPage) return;

    navigate(searchPage);
  };

  const checkIconIsSelected = (code) => {
    if (!filters.topic) return '';

    const selected = filters.topic;

    return code === selected ? 'selected' : '';
  };

  if (topicsLoading) {
    return (
      <S.Container>
        <Swiper {...swiperProps}>
          {Array.from({ length: 15 }).map((_, index) => (
            <SwiperSlide key={index}>
              <Skeleton.Node active style={{ width: 65 }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </S.Container>
    );
  }

  if (topicsError || !topics) {
    return (
      <S.Container>
        <span className="search-home__filter-title">
          {t('specialties.error')}
        </span>
      </S.Container>
    );
  }

  return (
    <S.Container>
      {!isMobile && (
        <span className="search-home__filter-title">
          {t('common.find')} {t('common.bySpecialty')}
        </span>
      )}
      <Swiper {...swiperProps}>
        {topics?.map(({ name, code }) => (
          <SwiperSlide
            className={`search-home__filter ${checkIconIsSelected(code)}`}
            key={code}
            onClick={() => handleClick({ code })}
          >
            <div className="search-home__filter-icon">{getIcon(code)}</div>
            <span className="search-home__filter-text">
              {t(`specialties.${name}`).split(' ')[0].replace(',', '')}
            </span>
          </SwiperSlide>
        ))}
      </Swiper>
      {isMobile && (
        <div className="swiper-button-container">
          <div className="swiper-button-prev">
            <ArrowLeftOutlined
              style={{ fontSize: '24px', color: colors.v2.primary[900] }}
            />
          </div>
          <div className="swiper-button-next">
            <ArrowRightOutlined
              style={{ fontSize: '24px', color: colors.v2.primary[900] }}
            />
          </div>
        </div>
      )}
    </S.Container>
  );
};

export default SearchIcons;
