import styled from 'styled-components';

export const Container = styled.div`
  max-width: ${({ theme }) => theme.containerSize};
  margin: 0 auto;

  .home__content {
    transform: translateY(-80px);

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      transform: translateY(-85px);
    }
  }
`;
