import { Table, Tabs } from 'antd';
import styled from 'styled-components';

import { FIXED_VALUES } from 'constants/css-fixed-values';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - ${FIXED_VALUES.navbar}px);
  ${PaddingContainer}
  padding: 24px 24px 36px;

  .ant-tabs-nav {
    border-bottom: 0px solid ${(props) => props.theme.colors.secondary.main};
  }

  .ant-tabs-ink-bar {
    background-color: transparent !important;
  }

  .ant-tabs-top > .ant-tabs-nav:before {
    border-bottom: 0px solid #f0f0f0;
  }

  .ant-tabs-nav-list {
    border-bottom: 0px solid #f0f0f0;
  }

  .membership__about {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-content {
      display: flex;
      flex-flow: column wrap;
    }

    &-label {
      color: ${(props) => props.theme.colors.neutralBlack[20]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
    }

    &-value {
      color: ${(props) => props.theme.colors.neutralBlack[30]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 22px;
    }
  }

  .membership__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 24px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-flow: row nowrap;
    }

    .ant-btn {
      width: 100%;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: auto;
      }
    }
  }

  .membership__registered-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-top: 8px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .membership__registered-cards__card {
      min-height: 110px;

      .card__header {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        position: relative;

        .card__name {
          color: ${(props) => props.theme.colors.neutralBlack[20]};
          font-family: ${(props) => props.theme.fonts.texts};
          font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          font-size: ${convertPxToRem(14)};
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 0;
        }

        .card__status {
          align-items: center;
          color: ${(props) => props.theme.colors.success[30]};
          display: flex;
          font-family: ${(props) => props.theme.fonts.texts};
          font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
          font-size: ${convertPxToRem(14)};
          font-style: normal;
          font-weight: 700;
          letter-spacing: 1.25px;
          line-height: 22px;
        }

        .card__remove-button {
          background: none;
          border: none;
          padding: 8px;
          position: absolute;
          right: -36px;
          top: -40px;
        }
      }

      .ant-divider {
        margin: 8px 0 24px;
      }

      .card__number {
        color: ${(props) => props.theme.colors.neutralBlack[20]};
        font-family: ${(props) => props.theme.fonts.texts};
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
        font-size: ${convertPxToRem(16)};
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1.25px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }

    .membership__registered-cards__new {
      min-height: 110px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-upload-wrapper {
    display: block;
  }
`;

export const ProfileTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    margin: 16px 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      flex-flow: row wrap;
      margin: 0 0 16px;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 8px 0;
  }

  .ant-tabs-tab {
    color: ${(props) => props.theme.colors.neutralBlack[20]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 22px;
    margin: 0 24px 0;
    text-align: center;
  }

  .ant-tabs-tab-active {
    span {
      font-weight: 800;

      color: ${(props) => props.theme.colors.neutralBlack[40]};

      &::after {
        border-bottom: 2px solid ${({ theme }) => theme.colors.blue[20]};
        content: '';
        display: block;
        position: relative;
        top: 4px;
        right: 16px;
        width: calc(100% + 32px);
      }
    }
    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.blue[40]};
      font-weight: 700;
    }
  }

  .actions-certificate {
    display: flex;
    flex-flow: column wrap;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      flex-flow: row nowrap;
    }

    .ant-btn {
      font-size: 12px;
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        width: auto;
      }

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }

  .tabs {
    &__personal-tab {
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: flex;
        flex-flow: column-reverse wrap;
      }
    }
  }

  .ant-card-bordered {
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    margin-bottom: 24px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 16px;
    }

    .card__subtitle {
      font-family: ${(props) => props.theme.fonts.texts};
      font-size: 14px;
    }

    .stripe-logo {
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
    }
  }

  .idioms {
    box-shadow: none !important;

    footer {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        float: right;
        width: 30%;
      }
    }
  }

  .financial__payment-rules {
    padding: 0 16px;
    margin-bottom: 24px;
    p {
      color: ${(props) => props.theme.colors.neutralBlack[40]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .video-tab {
    .ant-card {
      height: 100%;
    }

    footer {
      margin-top: 96px;
    }
  }

  .video-tab__dragger {
    width: 100%;

    .ant-upload-drag {
      border-color: ${(props) => props.theme.colors.neutralBlack[30]};
      padding: 24px;
    }

    .video-tab__dragger-text {
      color: ${(props) => props.theme.colors.neutralBlack[20]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-size: ${convertPxToRem(16)};
      text-align: center;

      strong {
        color: ${(props) => props.theme.colors.v2.secondary[400]};
        text-decoration: underline;
      }
    }

    .video-tab__size-message {
      color: ${(props) => props.theme.colors.neutralBlack[20]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 24px;
      text-align: center;
    }
  }
`;

export const CardTitle = styled.h3`
  color: ${(props) => props.theme.colors.neutralBlack[20]};
  font-family: ${(props) => props.theme.fonts.texts};
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const ContentaPaymentTable = styled(Table)`
  .ant-table-thead {
    th.ant-table-cell {
      background-color: ${(props) => props.theme.colors.neutralWhite[10]};
      color: ${(props) => props.theme.colors.neutralBlack[20]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(18)};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }

  .ant-table-row {
    font-family: ${(props) => props.theme.fonts.texts};
    font-style: normal;
    font-weight: 400;
    font-size: ${convertPxToRem(16)};
    line-height: 22px;
    letter-spacing: 1.25px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: ${(props) => props.theme.colors.neutralBlack[30]};
  }
`;

export const PaymentConfirmedModal = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.neutralWhite[10]};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  > img {
    margin-bottom: 16px;
  }

  > h2 {
    align-items: center;
    color: ${(props) => props.theme.colors.neutralWhite[10]};
    display: flex;
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(28)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
    text-align: center;
  }

  > p {
    font-family: ${(props) => props.theme.fonts.texts};
    font-style: normal;
    font-weight: 400;
    font-size: ${convertPxToRem(16)};
    line-height: 22px;
    letter-spacing: 1.25px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: ${(props) => props.theme.colors.neutralWhite[10]};
  }

  > footer {
    justify-content: center;
    display: flex;
  }
`;

export const FailedPaymentModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  > img {
    margin-bottom: 16px;
  }

  > h2,
  > h4,
  p {
    align-items: center;
    color: ${(props) => props.theme.colors.neutralBlack[40]};
    display: flex;
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(28)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
    text-align: center;
  }

  h4 {
    font-weight: 700;
    font-size: ${convertPxToRem(16)};
    line-height: 22px;
  }

  > p {
    font-style: normal;
    font-weight: 400;
    font-size: ${convertPxToRem(16)};
    line-height: 22px;
    letter-spacing: 1.25px;
  }

  > footer {
    justify-content: center;
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
  }
`;
