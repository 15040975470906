import styled from 'styled-components';

export const Container = styled.div`
  height: 150px;
  margin: 0 auto 28px;
  max-width: 800px;
  padding: 20px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    height: auto;
    margin-top: 30px;
    max-width: ${({ theme }) => theme.containerSize};
    padding: 0;
  }

  .search-home__filter-title {
    color: ${({ theme }) => theme.colors.v2.primary[900]};
    display: block;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    margin-bottom: 24px;
    position: relative;
    width: 100%;
  }

  .search-home__filter-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0%;
    vertical-align: middle;
    margin-top: 4px;
  }

  .swiper {
    height: 100%;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin: 0;
    }
  }

  .swiper-button-container {
    position: relative;
    top: 0;
  }

  .search-home__filter {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 4px;
    max-height: 80px;

    &.selected {
      background-color: ${({ theme }) => theme.colors.v2.neutral[50]};
      border-radius: 4px;

      .search-home__filter-icon {
        svg,
        path {
          fill: ${({ theme }) => theme.colors.v2.secondary[400]}!important;
        }
      }
    }
  }

  .search-home__filter-icon {
    height: 32px;
  }
`;
