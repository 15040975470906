import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { useFindExpert } from 'contexts/find-expert-context';

import fetcher from 'services/fetcher';

const buildQueryParams = (filters) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      params.append(key, value);
    }
  });

  return params.toString();
};

const useList = () => {
  const { filters, getMoreData } = useFindExpert();

  const [mentorList, setMentorList] = useState([]);
  const [prevTopic, setPrevTopic] = useState(filters.topic);

  const queryParams = buildQueryParams(filters);

  const { data, isLoading } = useSWR(`advisor/public?${queryParams}`, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 0,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (prevTopic !== filters.topic) {
      setMentorList([]);
      setPrevTopic(filters.topic);
      return;
    }

    if (data?.content && !isLoading && filters.page === 0) {
      setMentorList(data.content);
    } else if (data?.content && !isLoading) {
      setMentorList((prevList) => [...prevList, ...data.content]);
    }
  }, [data, filters.page]);

  return {
    mentorList,
    expertList: mentorList,
    isLoading,
    pagination: {
      last: data?.last,
    },
    getMoreData,
  };
};

export default useList;
