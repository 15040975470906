export const unloggedRoutesOptions = [
  '/',
  '/advisor-details/',
  '/faq',
  '/meet',
  '/lobby',
  '/search-advisor',
  '/terms-and-conditions',
  '/privacy-policy',
  '/logout',
  '/checkout-gift',
  '/about-us',
  'not-allowed',
  '/experiences',
];

const unloggedRoutesStartsWith = [
  '/meet/',
  '/lobby/',
  '/advisor-details/',
  '/gift-advisor-profile/',
];

const isUnloggedRoute = (route) => {
  if (!route) {
    return false;
  }

  return (
    unloggedRoutesOptions?.includes(route) ||
    unloggedRoutesStartsWith?.some((unloggedRoute) =>
      route.startsWith(unloggedRoute)
    )
  );
};

export default isUnloggedRoute;
