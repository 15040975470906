import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.v2.neutral[50]};
  padding: 20px;

  .share-experiences__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: ${({ theme }) => theme.containerSize};
      margin: 0 auto;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .share-experiences__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      flex-direction: row;
      gap: 20px;
    }
  }

  .share-experiences__content-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: center;
  }

  .share-experiences__contenta {
    color: ${({ theme }) => theme.colors.v2.secondary[400]};
  }

  .ant-btn {
    border-radius: 4px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: 234px;
    }
  }
`;
