import { RoundedStar } from '@smastrom/react-rating';
import { colors } from 'styles/themes/default';

export const customStarsStyles = {
  itemShapes: RoundedStar,
  activeFillColor: colors.v2.primary[500],
  inactiveFillColor: colors.v2.primary[50],
};

export const starsProfileAdvisors = {
  itemShapes: RoundedStar,
  activeFillColor: colors.pink[20],
  inactiveFillColor: colors.pink[10],
};
