import { Layout } from 'antd';
import PropTypes from 'prop-types';

import toggleDrawer from 'utils/toggleDrawer';
import { FooterApp } from './FooterApp';
import { SiderApp } from './SiderApp';
import * as S from './styles';
import { NavbarV2 } from './NavbarV2';

export function ContainerApp({ children, hasBgImg }) {
  const isRegisterPage =
    window.location.pathname === '/guide/register' ||
    window.location.pathname === '/register';

  const isMeetingsPage = window.location.pathname === '/meet/video';
  const isChatPage = window.location.pathname === '/chat';
  const canShowFooter = !isRegisterPage && !isMeetingsPage && !isChatPage;

  function showDrawer() {
    toggleDrawer();
  }

  return (
    <Layout>
      <SiderApp />

      <S.Container>
        {!isRegisterPage && <NavbarV2 showDrawer={showDrawer} />}
        <S.Wrapper isRegisterPage={isRegisterPage} hasBgImg={hasBgImg}>
          {children}
        </S.Wrapper>
      </S.Container>

      {canShowFooter && <FooterApp />}
    </Layout>
  );
}

ContainerApp.propTypes = {
  children: PropTypes.node.isRequired,
  hasBgImg: PropTypes.bool,
  isMentor: PropTypes.bool,
};

ContainerApp.defaultProps = {
  hasBgImg: false,
  isMentor: false,
};
