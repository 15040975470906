import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import { v4 as uuidv4 } from 'uuid';

import { Rating } from '@smastrom/react-rating';
import Avatar from 'components/Avatar';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';

import useRedirectToAdvisorPage from 'hooks/useRedirectToAdvisorPage';

import { FlagsCodeImage } from 'constants/flag-code';
import { customStarsStyles } from 'constants/stars-colors';

import bgImg from 'assets/images/default-city.webp';

import * as S from './styles';
import { OrangeButton } from 'components/Styled/v2/OrangeButton';

function MentorCard({ mentor }) {
  const { t } = useTranslation();
  const { redirectToAdvisorPage } = useRedirectToAdvisorPage();

  const handleRedirectToMentor = () => {
    redirectToAdvisorPage(mentor);
  };

  const coverPhotoBg = mentor?.coverURL || bgImg;

  return (
    <S.MentorCardContainer>
      <div
        className="mentor-card__cover-photo"
        style={{ backgroundImage: `url(${coverPhotoBg})` }}
      />
      <header className="mentor-card__header" onClick={handleRedirectToMentor}>
        <Avatar src={mentor.imageURL} alt={mentor?.name} />

        <div className="mentor-card__info">
          <h2 className="mentor-card__country">
            {mentor?.address?.city || mentor?.city} -{' '}
            {mentor?.address?.country?.name ||
              mentor?.country ||
              t('home.countryNotInformed')}
          </h2>
          <h3 className="mentor-card__name">{mentor?.name}</h3>
        </div>
      </header>

      <div className="mentor-card__body">
        <div className="mentor-card__idioms">
          <span className="mentor-card__idioms-text">
            {t('common.idioms')}:
          </span>
          <span className="mentor-card__idioms-options">
            {mentor?.languages?.map(({ code }) => (
              <Tooltip title={t(`languages.code.${code}`)} key={uuidv4()}>
                {FlagsCodeImage[code]}
              </Tooltip>
            ))}
          </span>
        </div>

        <div className="mentor-card__rating">
          <span className="mentor-card__idioms-text">
            {t('mentorProfile.rating')}
          </span>
          <Rating
            className="evaluate-modal__rate-stars"
            spaceBetween={4}
            style={{ maxWidth: 88 }}
            value={mentor?.rating || 0}
            isDisabled
            itemStyles={customStarsStyles}
          />
        </div>
      </div>

      <div className="mentor-card__button">
        <OrangeButton
          onClick={handleRedirectToMentor}
          className="mb-16"
          size="small"
        >
          {t('mentorProfile.expertPage')}
        </OrangeButton>
      </div>

      <div className="mentor-card__specialties">
        <span className="mentor-card__specialties-text">
          {t('mentorProfile.specialties')}:
        </span>
        {mentor.topics?.map((topic, index) => (
          <>
            <span className="mentor-card__specialties-options contenta-text">
              {t(`specialties.${topic.name}`)}
            </span>
            {index !== mentor.topics.length - 1 && (
              <span className="mentor-card__specialties-separator"> | </span>
            )}
          </>
        ))}
      </div>
    </S.MentorCardContainer>
  );
}

export default MentorCard;
