import styled from 'styled-components';

export const Container = styled.div`
  .infinite-scroll-component {
    overflow-x: hidden !important;
  }
  .travel-expert-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    gap: 10px;
    justify-content: center;
    margin-bottom: 32px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      grid-template-columns: repeat(3, fr);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      grid-template-columns: repeat(4, 1fr);
    }

    .expert-card {
      width: 100%;
    }
  }
`;
