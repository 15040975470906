import SearchBar from '../home/components/SearchBar';
import SearchIcons from '../home/components/SearchIcons';

import 'swiper/css';
import 'swiper/css/effect-fade';

import TravelExpertList from './components/list';

import * as S from './styles';

const FindTravelExpert = () => {
  return (
    <main>
      <S.Container>
        <SearchBar />
        <SearchIcons />
        <TravelExpertList />
      </S.Container>
    </main>
  );
};

export default FindTravelExpert;
