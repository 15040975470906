import { useTranslation } from 'react-i18next';

import { Form, Input } from 'antd';

import { ContentaInput } from 'components/Styled/ContentaInput';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';

function GiftCardDataForm({ form }) {
  const { t } = useTranslation();
  return (
    <Form form={form} layout="vertical" requiredMark={false}>
      <ContentaInputGroup>
        <Form.Item
          name="name"
          label={t('giftCardModal.name')}
          rules={[
            {
              required: true,
              message: t('guides.chooseAOptionRequired'),
            },
          ]}
        >
          <ContentaInput placeholder={t('giftCardModal.name')} />
        </Form.Item>
      </ContentaInputGroup>

      <ContentaInputGroup>
        <Form.Item
          name="email"
          label={t('giftCardModal.email')}
          rules={[
            {
              required: true,
              message: t('guides.chooseAOptionRequired'),
            },
          ]}
        >
          <ContentaInput placeholder={t('giftCardModal.email')} type="email" />
        </Form.Item>
      </ContentaInputGroup>

      <ContentaInputGroup>
        <Form.Item name="message" label={t('giftCardModal.message')}>
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            placeholder={t('giftCardModal.message')}
            showCount
            maxLength={500}
          />
        </Form.Item>
      </ContentaInputGroup>
    </Form>
  );
}

export default GiftCardDataForm;
