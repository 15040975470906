import { useState } from 'react';

import { ContentaBreadcrumb } from 'components/ContentaBreadcrumb';
import { useTranslation } from 'react-i18next';

import { comments } from 'constants/user-testemonials';
import { Card } from 'antd';

import './styles.scss';

const MAX_COMMENT_LENGTH = 170;

const Experiences = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);

  return (
    <div className="experiences__container">
      <ContentaBreadcrumb
        items={[
          {
            link: '/',
            label: 'Home',
          },
          {
            label: 'breadcrumb.testimonials',
          },
        ]}
      />
      <h1 className="text-3xl font-bold">{t('home.comments.title')}</h1>

      <div className="experiences__content">
        {comments.map(
          ({ name, comment, image, type, typeUpload, avatar }, index) => {
            const isExpanded = expandedIndex === index;
            const slicedComment = isExpanded
              ? comment
              : comment.length > MAX_COMMENT_LENGTH
              ? `${comment.slice(0, MAX_COMMENT_LENGTH)}...`
              : comment;

            return (
              <Card>
                {typeUpload === 'video' ? (
                  activeVideoIndex === index ? (
                    <div className="comments__video-wrapper">
                      <img
                        src={avatar}
                        alt=""
                        className="comments__video-blur-bg"
                      />
                      <video
                        className="comments__card-header comments__card-header--video"
                        src={image}
                        controls
                        autoPlay
                        muted
                        playsInline
                      />
                    </div>
                  ) : (
                    <div
                      className="comments__card-header comments__video-thumbnail"
                      onClick={() => setActiveVideoIndex(index)}
                    >
                      <img src={avatar} alt={`Thumbnail for ${name}'s video`} />
                      <div className="comments__video-overlay" />
                      <div className="comments__play-button" />
                    </div>
                  )
                ) : (
                  <div
                    className="comments__card-header"
                    style={{ backgroundImage: `url(${image})` }}
                  />
                )}

                <p className="comments__card-comment">{slicedComment}</p>

                {comment.length > MAX_COMMENT_LENGTH && (
                  <div className="comments__card-action">
                    <a
                      href="#"
                      className="comments__card-footer-link"
                      onClick={(e) => {
                        e.preventDefault();
                        setExpandedIndex(isExpanded ? null : index);
                      }}
                    >
                      {isExpanded ? 'Show less' : 'Read more'}
                    </a>
                  </div>
                )}

                <div className="comments__card-user">
                  <img src={avatar} alt={name} />
                  <div>
                    <span className="comments__card-user-name">{name}</span>
                    <span
                      className={`comments__card-user-type comments__card-user-type--${type}`}
                    >
                      {type}
                    </span>
                  </div>
                </div>
              </Card>
            );
          }
        )}
      </div>
    </div>
  );
};

export default Experiences;
