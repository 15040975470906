import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const NotTalkedContainer = styled.div`
  ${PaddingContainer}
  margin: 24px 0 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-right: 0;
  }

  .not-talked__mentors {
    h3 {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 800;
      margin: 0 0 8px;
      padding: 0 12px;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: ${convertPxToRem(24)};
        margin: 16px 0 24px;
      }
    }
  }

  .not-talked__mentors-card {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    height: 550px;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: auto;
    }
  }

  .swiper-slide {
    width: auto !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: ${({ theme }) => theme.colors.v2.secondary[400]};
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
  }
`;
