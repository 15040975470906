import { useNavigate } from 'react-router-dom';
import findCountryNameById from 'utils/findCountryNameById';

const useRedirectToAdvisorPage = () => {
  const navigate = useNavigate();

  const redirectToAdvisorPage = (advisor, state = {}) => {
    const { country, name, id } = advisor;

    let foundCountry;

    if (country?.code) {
      foundCountry = findCountryNameById({ id: country.code });
    }
    if (country?.acronym) {
      foundCountry = findCountryNameById({ id: country?.acronym });
    }
    if (typeof country === 'string') {
      foundCountry = country;
    }

    foundCountry = foundCountry === 'N/A' ? country?.acronym : foundCountry;
    const countryFormatted = foundCountry.replace(/\s/g, '-');
    const nameFormatted = name.replace(/\s/g, '-');

    navigate(`/advisor-details/${countryFormatted}/${nameFormatted}/${id}`, {
      state,
    });
  };

  return { redirectToAdvisorPage };
};

export default useRedirectToAdvisorPage;
