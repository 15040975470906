import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './locales';

let localStorageLanguage = sessionStorage.getItem('@contenta-language');
if (!localStorageLanguage || localStorageLanguage === 'undefined') {
  localStorageLanguage = 'en-US';
  sessionStorage.setItem('@contenta-language', localStorageLanguage);
}

const i18nConfig = {
  resources: translations,
  fallbackLng: localStorageLanguage || 'en-US',
  defaultNS: 'translations',
};

i18n.use(initReactI18next).init(i18nConfig);

export default i18n;
