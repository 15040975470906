import styled from 'styled-components';

import mobileBg from '../../../../../assets/images/home/gift-card-mobile.png';
import desktopBg from '../../../../../assets/images/home/home-gift-desktop.png';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.div`
  background-image: url(${mobileBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 375px;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-image: url(${desktopBg});
    background-size: contain;
    border-radius: 10px;
    height: 182px;
  }

  .gift-card__content {
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: flex-start;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: 50%;
    }
  }

  .gift-card__text {
    color: ${({ theme }) => theme.colors.v2.backgroundColor};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${convertPxToRem(24)};
    font-weight: 400;
    margin: 0;
    padding: 20px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: ${convertPxToRem(40)};
    }
  }

  .gift-card__button {
    max-width: 185px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 300px;
    }
  }
`;
