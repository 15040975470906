import Comments from './components/Comments';
import ExpertsCarousel from './components/ExpertsCarousel';
import GiftCard from './components/GiftCard';
import SearchBar from './components/SearchBar';
import SearchIcons from './components/SearchIcons';

import 'swiper/css';
import 'swiper/css/effect-fade';

import * as S from './styles';

const HomeV2 = () => {
  return (
    <S.Container>
      <SearchBar />
      <section className="home__content">
        <SearchIcons />
        <ExpertsCarousel />
        <GiftCard />
        <Comments />
      </section>
    </S.Container>
  );
};

export default HomeV2;
