import { INTERVAL_TIMES } from 'constants/interval-times';
import { CONTENTA_TOKEN } from 'constants/storages-values';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { authService } from 'services/Auth0Service';

const useContentaAuth = ({ authUser, setIsAuthenticated, setAuthUser }) => {
  const [token, setToken] = useState(null);

  const renewToken = () => {
    authService.checkSession({}, (err, result) => {
      if (err) {
        console.error('Erro ao renovar token:', err);
      } else {
        setToken(result.accessToken);
      }
    });
  };

  const authenticateUser = (token = null) => {
    const accessToken = token || Cookies.get(CONTENTA_TOKEN);
    console.log('authenticateUser: ' + token ? 'with token' : 'without token');

    if (!accessToken) {
      console.log('Token não encontrado');
      return;
    }

    authService.client.userInfo(accessToken, function (err, user) {
      if (err) {
        console.log(err);
        Cookies.remove(CONTENTA_TOKEN);
        return;
      }
      if (user) {
        setIsAuthenticated(true);
        setAuthUser(user);
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const isUserEmpty = Object.keys(authUser).length === 0;

      if (isUserEmpty) {
        console.log('Usuário não autenticado');
        authenticateUser();
        return;
      }

      console.log('Renovando token');
      renewToken();
    }, INTERVAL_TIMES.FIVE_MINUTES);
    // }, 10000);

    return () => clearInterval(interval);
  }, [authUser]);

  return {
    token,
  };
};

export default useContentaAuth;
